/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'Airzen',
        clientId: 'quoi29-m365',
        buttonText: 'Identification Airzen',
      },
    ],
  ],
}
