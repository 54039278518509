<template>
  <div class="w-block__pre-heading">
    <!-- pre-heading block -->
    <v-container
      v-if="$route.name === 'index' && $voicer.isOpenSite() === false"
      class="pa-2"
    >
      <div>
        Le Quoi 2 Neuf c’est votre podcast, n’hésitez pas à nous faire vos
        suggestions à
        <a href="mailto:quoi29@mediameeting.fr">quoi29@mediameeting.fr</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
